import { formatDate } from 'date-fns';
import { useInfiniteQuery } from 'react-query';

import { DEFAULT_LOGO } from '~/constants/general';
import { useApi } from '~/hooks/useApi';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useGetDeployments } from '~/hooks/useGetDeployments';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import {
  QueryServiceMetricsConfig,
  qsTranscriptMessageDtoToTranscript,
} from '~/types/query-service-helpers';
import { QueryServiceApi } from '~/utils/http/api';
import { DeployType } from '~/utils/http/deployment/types';
import { Transcript } from '~/utils/http/transcript/types';

type Params = {
  deployId?: number;
  userId: string | null;
  sessionEventId: string | undefined;
  initialData: Transcript[];
};

type GroupedTranscripts = {
  [date: string]: Transcript[];
};

export const useTranscript = ({
  deployId,
  sessionEventId,
  userId,
  initialData,
}: Params) => {
  const user = useCurrentUser();
  const { selectedAgent } = useSelectedChatbot();

  const { data: deployments } = useGetDeployments({
    agentId: selectedAgent?.id,
    enabled: !deployId,
  });

  const { data: deployment } = useApi(
    ['getDeployment', deployId],
    (Api) => Api.deployment.getDeployment(deployId!),
    { enabled: Boolean(deployId) }
  );

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(['transcripts', userId, sessionEventId], {
      enabled: Boolean(
        user.hasPIIRole &&
          (userId || sessionEventId) &&
          initialData.length === 0
      ),
      queryFn: async ({ pageParam = 1 }) => {
        const data = await QueryServiceApi.query.query({
          requestBody: {
            conditions: {
              user_id: userId!,
              session_event_id: sessionEventId,
              customer_account: {
                external_id: user.account_id,
              },
            },
            projection: [
              {
                metric: QueryServiceMetricsConfig.SessionTranscripts.metric,
                views: QueryServiceMetricsConfig.SessionTranscripts.views,
                options: {
                  'transcripts/items_per_page': '10',
                  'transcripts/page_number': pageParam?.toString(),
                  'transcripts/session_order': 'desc',
                  'transcripts/message_order': 'desc',
                },
              },
            ],
          },
        });

        const transcripts =
          data.sessions?.content?.transcripts?.page_items?.flatMap(
            (t) => t.messages.map(qsTranscriptMessageDtoToTranscript) ?? []
          ) ?? [];

        return {
          data: transcripts,
          page: data.sessions?.content?.transcripts?.page_number ?? 1,
          totalPages: data.sessions?.content?.transcripts?.page_total,
        };
      },
      getNextPageParam: ({ page, totalPages }) => {
        return page === totalPages ? undefined : page + 1;
      },
      getPreviousPageParam: ({ page }) => page - 1,
    });

  const avatar = (() => {
    const webchatDeployments =
      deployments?.filter(
        (d) =>
          d.deploy_type === DeployType.Webchat && d.deploy_attributes?.bot_url
      ) ?? [];

    const activeDeployment = webchatDeployments.find((d) => !d.is_sandbox);

    return (
      (deployment || activeDeployment || webchatDeployments[0])
        ?.deploy_attributes?.bot_url ?? DEFAULT_LOGO
    );
  })();

  const groupTranscriptsByDate = (
    transcripts: Transcript[]
  ): GroupedTranscripts => {
    return transcripts.reduce((acc, curr) => {
      const isCurrentYear =
        new Date(curr.timestamp).getFullYear() === new Date().getFullYear();

      const date = formatDate(
        curr.timestamp,
        `EEE d. MMM${isCurrentYear ? '' : ' yyyy'}`
      );

      return {
        ...acc,
        [date]: [...(acc[date] ?? []), curr],
      };
    }, {} as GroupedTranscripts);
  };

  const transcripts = ((): Transcript[] => {
    if (initialData.length) return initialData;

    return data?.pages?.map((page) => page.data).flat() ?? [];
  })();

  return {
    avatar,
    loadingData: isLoading,
    fetchNextPage,
    hasNextPage,
    transcripts: groupTranscriptsByDate(transcripts),
    isFetchingNextPage,
  };
};
