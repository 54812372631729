import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@botco/library';
import CrossIcon from '~/assets/icons/cross.svg?react';
import PencilIcon from '~/assets/icons/pencil.svg?react';
import { ConversationsListContainer } from '~/containers/Conversations/ConversationsList/ConversationsListContainer';
import { AgentType } from '~/containers/Conversations/ConversationsList/types';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { getCurrentRouteRecord } from '~/utils';

export const SelectedChatbotButton = () => {
  const classes = useStyles();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { selectedAgent } = useSelectedChatbot();

  const handleOpenModal = () => setIsModalOpen(true);

  const handleSelectChatbot = (agent?: AgentType) => {
    setIsModalOpen(false);

    const { route, match } = getCurrentRouteRecord(history.location.pathname);

    const isSameAgent = agent?.id === selectedAgent?.id;

    if (route && agent && !isSameAgent) {
      const params = new URLSearchParams(window.location.search);
      history.push(
        `${route.get(agent.id, match?.params ?? {})}${!selectedAgent ? `?${params}` : ''}`
      );
    }
  };

  return (
    <>
      <Button id="selected-chatbot-button" onClick={handleOpenModal}>
        {selectedAgent?.agent_name ?? 'Select Chatbot'}
        <PencilIcon className={classes.editButton} />
      </Button>

      {isModalOpen && (
        <Dialog
          open
          keepMounted
          maxWidth="xl"
          classes={{ paper: classes.modalPaper }}
          onClose={() => handleSelectChatbot()}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            disableRipple
            onClick={() => handleSelectChatbot()}
          >
            <CrossIcon />
          </IconButton>
          <DialogContent>
            <ConversationsListContainer
              onSelectingChatbot={handleSelectChatbot}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    padding: 0,
    position: 'absolute',
    right: 14,
    top: 10,
    color: 'black',
    '&:hover': {
      background: 'none',
    },
  },
  modalPaper: {
    width: '80%',
    maxHeight: 'calc(100% - 240px)',
  },
  editButton: {
    width: 18,
    height: 18,
    marginLeft: theme.spacing(0.5),
  },
}));
