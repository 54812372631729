import { Box, Divider, Tab, Tabs } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Typography } from '@botco/library';
import { Attributes } from '~/components/Attributes';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { setCardParams } from '~/redux/actions/conversationDataR';
import * as yup from '~/utils/yup';

import { ApiAttributes } from './ApiAttributes';
import { APIJSONTemplate } from './APIJSONTemplate';
import { BlocksDropdown } from '../../components/BlocksDropdown';
import { ApiUsageTypeEnum } from '../APICard.types';
import {
  API_RESPONSE_TYPES,
  APIResponseChoiceItems,
} from '../constants/options';

type Props = {
  index: number;
};

export const APICardTabs = ({ index }: Props) => {
  const dispatch = useDispatch();
  const { selectedAgent } = useSelectedChatbot();
  const [tab, setTab] = React.useState<ApiUsageTypeEnum>(
    ApiUsageTypeEnum.SUCCESS
  );
  const { cardData } = useSelector(({ conversationsDataR }) => ({
    cardData: conversationsDataR.intentCardsData?.[index],
  }));

  const handleParamChange = (
    paramKey: 'param1' | 'param2' | 'param3' | 'param4',
    value: unknown
  ) => {
    dispatch(
      setCardParams({
        cardIndex: index,
        param1: cardData.param1,
        param2: cardData.param2,
        param3: cardData.param3,
        param4: cardData.param4,
        [paramKey]: value,
      })
    );
  };

  const [apiResponseType, setApiResponseType] =
    React.useState<API_RESPONSE_TYPES>(API_RESPONSE_TYPES.SET_ATTRIBUTE);

  const onErrorBlock = yup.safeNumber().nullable().cast(cardData.param3);

  return (
    <section id="api-card-tabs">
      <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          style={{ gap: 8 }}
          mb={2}
        >
          <Typography color="textPrimary" align="left" fontWeight="600">
            API Call Status
          </Typography>
          <Attributes.Input
            agentId={selectedAgent?.id!}
            onChange={(attribute) =>
              handleParamChange('param2', attribute?.cust_attr_name)
            }
            value={{
              cust_attr_name: cardData.param2,
            }}
          />
        </Box>
        <Box width="50%">
          <BlocksDropdown
            label="On Error Block"
            placeholder="Select a block"
            blockId={onErrorBlock}
            onChange={(blockId) =>
              handleParamChange('param3', blockId?.toString())
            }
          />
        </Box>
        <Tabs
          value={tab}
          onChange={(_, tab) => setTab(tab)}
          indicatorColor="primary"
          centered
          textColor="primary"
        >
          <Tab label="Success" value={ApiUsageTypeEnum.SUCCESS} />
          <Tab label="Failure" value={ApiUsageTypeEnum.FAILURE} />
        </Tabs>
        <Divider />

        <Select
          fullWidth
          options={APIResponseChoiceItems}
          current={apiResponseType}
          onChange={(value) => setApiResponseType(value as API_RESPONSE_TYPES)}
        />
        {apiResponseType === API_RESPONSE_TYPES.JSON_TEMPLATES && (
          <APIJSONTemplate
            jsonTemplates={cardData.api_templates ?? []}
            orderIndex={index}
            apiUsageType={tab}
          />
        )}
        {apiResponseType === API_RESPONSE_TYPES.SET_ATTRIBUTE && (
          <ApiAttributes index={index} type={tab} />
        )}
      </Box>
    </section>
  );
};
