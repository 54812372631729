import { AxiosResponse } from 'axios';

import { constants } from '~/config';

import { firebaseClient } from '../../firebaseClient';
import type { DashboardSettings } from '../types';

type Params = {
  accountId: number;
  agentId: number;
};

export const getDashboardSettings = async ({
  accountId,
  agentId,
}: Params): Promise<AxiosResponse<DashboardSettings | undefined>> => {
  const response = await firebaseClient.get<DashboardSettings>(
    '/dashboardSettings',
    {
      params: {
        orgID: accountId,
        chatbotID: agentId,
        env: constants.app_env,
      },
    }
  );

  // Firebase returns a html string when the data is empty
  const isString = typeof response.data === 'string';

  if (isString) {
    return { ...response, data: undefined };
  }

  return response;
};
