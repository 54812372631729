import { BotcoAPI } from '@botco/api';
import { constants } from '~/config';
import { generalSaveError } from '~/constants';
import { routes } from '~/constants/routes';
import {
  showToast,
  handleErrors,
  cardObjectCreator,
  toISOTimeFormat,
  generateGotoButtonData,
  validateCard,
} from '~/utils';

import * as actionTypes from '../types/conversationDataR';

const newApiCall = new BotcoAPI(constants.api_url);

export const updateSubmissionCardData = (index, payload) => {
  return {
    type: actionTypes.UPDATE_SUBMISSION_CARD_STATE_R,
    payload,
    index,
  };
};

export const getDeployments = () => (dispatch) =>
  newApiCall
    .getDeployments()
    .then((response) => {
      return dispatch({
        type: actionTypes.LIST_ALL_DEPLOYMENTS,
        payload: response.data,
      });
    })
    .catch((err) => handleErrors(err, 'Failed to fetch deployed details'));

/* ****
GENERAL ACTIONS
**** */

export const getMe = () => async (dispatch) => {
  try {
    const { data: myData } = await newApiCall.getMe();
    return dispatch({
      type: actionTypes.GET_ME_DATA,
      payload: {
        userAvatar: myData.user_avatar,
        userName:
          [myData.first_name, myData.last_name]
            .filter(Boolean)
            .join(' ')
            .trim() || 'n/a',
        ...myData,
      },
    });
  } catch (error) {
    handleErrors(error, 'Could not get user.');
  }
};

/**
 * @param {object} file
 * @param {number} index
 * @param {number} [slideIndex]
 * @param {boolean} [video]
 * @param {number} [buttonOrder]
 * @param {function} [onSuccess]
 * @param {function} [onFailure]
 */
export const uploadFileToS3 = ({
  file,
  index,
  slideIndex = 0,
  video = false,
  buttonOrder = -1,
  onSuccess,
  onFailure,
}) => {
  const body = new FormData();
  body.append('file', file);

  return (dispatch) => {
    newApiCall
      .uploadFile(body)
      .then((response) => {
        onSuccess && onSuccess(response.data.location);
        return dispatch({
          type: actionTypes.HANDLE_S3_UPLOAD_R,
          url: response.data.location,
          index,
          slideIndex,
          buttonOrder,
        });
      })
      .catch((err) => {
        handleErrors(err, `Failed to upload ${video ? 'video' : 'image'}`);
        onFailure && onFailure();
      });
  };
};

export const getAllAgents = () => {
  return (dispatch) =>
    newApiCall
      .getAgents()
      .then(({ data }) => {
        const newData = data.map((item) => ({
          ...item,
          agent_id: item.id,
          agent_name: item.agent_name,
          is_template: item.is_template,
        }));

        return dispatch({
          type: actionTypes.GET_ALL_AGENTS,
          payload: newData,
        });
      })
      .catch((err) => handleErrors(err, 'Could not fetch agents'));
};

export const deleteAgent = (agentId) => (dispatch) => {
  return newApiCall
    .deleteAgent(agentId)
    .then(() => {
      return dispatch({
        type: actionTypes.DELETE_AGENT,
        payload: agentId,
      });
    })
    .catch((error) => {
      handleErrors(error, 'Could not delete the agent.');
      throw error;
    });
};

export const updateAgent = (agent, cb) => (dispatch) => {
  return newApiCall
    .updateAgent(agent)
    .then(({ data }) => {
      if (cb) {
        cb(data);
      }
      return dispatch({
        type: actionTypes.UPDATE_AGENT,
        payload: data,
      });
    })
    .catch((error) =>
      handleErrors(
        error,
        error.response?.data?.agentName ?? 'Could not update agent'
      )
    );
};

/**
 * @typedef {object} IntentsByAgentPayload
 * @property {number} agentId
 * @property {number|null} welcomeIntentId
 * @property {boolean} [first]
 * @property {number|null} [intentId]
 *
 * @param {IntentsByAgentPayload} payload
 */
export const getIntentsByAgent = ({
  agentId,
  welcomeIntentId,
  first = false,
  intentId,
}) => {
  return async (dispatch) => {
    try {
      const intentNames = await newApiCall.getIntentCategories(agentId, true);
      if (!intentId && !welcomeIntentId) {
        return dispatch({
          type: actionTypes.GET_INTENTS_BY_AGENT_R,
          payload: {
            intent_names_result: intentNames.data,
          },
          agentId,
          first,
        });
      }
      const intentActions = await newApiCall.getCardsByIntent(
        agentId,
        intentId ?? welcomeIntentId
      );
      return dispatch({
        type: actionTypes.GET_INTENTS_BY_AGENT_R,
        payload: {
          intent_names_result: intentNames.data,
          data: intentActions.data,
        },
        agentId,
        first,
      });
    } catch (err) {
      handleErrors(err, 'Could not fetch agents');
    }
  };
};

export const getCardsByIntent = (agent_id, intent_id) => {
  if (!intent_id) return;
  return (dispatch) => {
    return newApiCall
      .getCardsByIntent(agent_id, intent_id)
      .then((response) => {
        return dispatch({
          type: actionTypes.GET_CARDS_BY_INTENT_R,
          payload: response.data,
          intent_id,
        });
      })
      .catch((err) => handleErrors(err, 'Could not fetch intent actions.'));
  };
};

export const getConnectionsByIntent = (agent_id, intent_id) => {
  return async (dispatch) => {
    try {
      const { data } = await newApiCall.getConnectionsByIntent(
        agent_id,
        intent_id
      );
      return dispatch({
        type: actionTypes.GET_CONNECTIONS_BY_INTENT,
        payload: data,
        intent_id,
      });
    } catch (error) {
      handleErrors(error, 'Could not fetch intent connections.');
    }
  };
};

export const addCard = (type, data) => {
  const newCard = cardObjectCreator(type, data);
  return {
    type: actionTypes.ADD_INTENT_CARD_R,
    payload: newCard,
  };
};

export const insertCard = (orderIndex, type, data) => {
  const newCard = cardObjectCreator(type, data);
  return {
    type: actionTypes.INSERT_INTENT_CARD_R,
    orderIndex,
    payload: newCard,
  };
};

export const reorderCards = (index, direction) => {
  return {
    type: actionTypes.REORDER_CARDS_R,
    index,
    direction,
  };
};

export const removeCard = (index) => {
  return {
    type: actionTypes.REMOVE_CARD_R,
    index,
  };
};

export const addChoiceButton = (buttonObj, index, buttonOrder) => {
  return {
    type: actionTypes.ADD_BUTTON_R,
    buttonObj,
    index,
    buttonOrder,
  };
};

/**
 * @param {number} id
 * @param {number} [agent_id]
 * @param {number} [action_choice_id]
 * @param {number} index
 * @param {number} buttonIndex
 * @param {number} intent_id
 */
export const removeChoiceButton = (
  id,
  agent_id,
  action_choice_id = null,
  index,
  buttonIndex,
  intent_id
) => {
  if (action_choice_id) {
    return (dispatch) => {
      newApiCall
        .removeActionButton(agent_id, intent_id, id, action_choice_id)
        .then(() =>
          dispatch({
            type: actionTypes.REMOVE_BUTTON_R,
            index,
            buttonIndex,
          })
        )
        .catch((err) => {
          handleErrors(err, 'Could not delete button');
        });
    };
  }
  return {
    type: actionTypes.REMOVE_BUTTON_R,
    index,
    buttonIndex,
  };
};

/**
 *
 * @param {number} agent_id
 * @param {number} intent_id
 * @param {number} action_id
 * @param {number} index
 * @param {number} buttonIndex
 * @param {number} slideIndex
 * @param {?object} [body]
 * @returns
 */
export const removeCarouselButton = (
  agent_id,
  intent_id,
  action_id,
  index,
  buttonIndex,
  slideIndex,
  body = null
) => {
  if (body) {
    return (dispatch) => {
      newApiCall
        .updateIntentAction(agent_id, intent_id, action_id, body)
        .then(() => {
          showToast('success', 'Button removed successfully');
          return dispatch({
            type: actionTypes.REMOVE_CAROUSEL_BUTTON_R,
            index,
            buttonIndex,
            slideIndex,
          });
        })
        .catch((err) => handleErrors(err, 'Could not update intent action'));
    };
  }
  return {
    type: actionTypes.REMOVE_CAROUSEL_BUTTON_R,
    index,
    buttonIndex,
    slideIndex,
  };
};

/**
 * @typedef {object} ReorderButtonPayload
 * @property {number} cardIndex
 * @property {number} fromIndex
 * @property {number} toIndex
 *
 * @param {ReorderButtonPayload} payload
 */
export const reorderChoiceButton = (payload) => {
  return {
    type: actionTypes.REORDER_BUTTON_R,
    payload,
  };
};

export const setCurrentForm = (id) => ({
  type: actionTypes.SET_CURRENT_FORM_R,
  payload: id,
});

export const addFormItem = (id, name) => {
  return {
    type: actionTypes.ADD_FORM_ITEM,
    payload: {
      value: id,
      label: name,
    },
  };
};
export const getFormData = (agent_id) => {
  return (dispatch) => {
    newApiCall
      .getAgentForms(agent_id)
      .then(({ data }) => {
        return dispatch({
          type: actionTypes.GET_FORMS_BY_AGENT_R,
          payload: data,
        });
      })
      .catch((err) => handleErrors(err, 'Could not fetch forms data'));
  };
};

/**
 *
 * @param {any} action
 * @param {number} index
 * @param {number} buttonOrder
 * @param {number} slideIndex
 */
export const setCurrentAction = (
  action,
  index,
  buttonOrder = null,
  slideIndex = -1
) => ({
  type: actionTypes.SET_CURRENT_INTENT_R,
  action,
  index,
  buttonOrder,
  slideIndex,
});

export const closeAddButtonModal = () => ({
  type: actionTypes.CLOSE_ADD_BUTTON_MODAL_R,
});

export const getAttributesAndBlocks = (agent_id) => (dispatch) => {
  newApiCall
    .getAgentAttributes(agent_id)
    .then(({ data }) => {
      return dispatch({
        type: actionTypes.FETCH_ATTRIBUTES_R,
        payload: data,
      });
    })
    .catch((error) => {
      handleErrors(error, 'Could not fetch attribute!');
    });

  newApiCall
    .getIntents(agent_id)
    .then(({ data }) => {
      return dispatch({
        type: actionTypes.FETCH_BLOCKS_R,
        payload: data.map((intent) => ({
          value: intent.id,
          label: intent.intent_name,
          type: intent.intent_type,
        })),
      });
    })
    .catch((error) => {
      handleErrors(error, 'Could not fetch blocks!');
    });
};

export const updateDelayForSequence = (intent_id, value, unit) => {
  const parsedTime = toISOTimeFormat(value, unit);
  return {
    type: actionTypes.UPDATE_SEQUENCE_DELAY_R,
    time: parsedTime,
    intent_id,
  };
};

/**
 * @typedef {object} SetCardParamsPayload
 * @property {number} cardIndex
 * @property {string} [param1]
 * @property {string} [param2]
 * @property {string} [param3]
 * @property {string} [param4]
 *
 * @param {SetCardParamsPayload} payload
 */
export const setCardParams = (payload) => {
  return {
    type: actionTypes.SET_CARD_PARAMS,
    payload,
  };
};

/**
 * @param {number} index
 * @param {'name' | 'value'} fieldName
 * @param {string} value
 */
export const addGoalCardData = (index, fieldName, value) => {
  return {
    type: actionTypes.GOAL_CARD_ADD_DATA_R,
    cardIndex: index,
    [fieldName]: value,
  };
};

/* ****
TEXT CARD ACTIONS
**** */
/* ****
QUICK REPLY CARD ACTIONS
**** */

export const addSetHideKeyboardData = (index = 0, value = false) => {
  return {
    type: actionTypes.SET_HIDE_KEYBOARD,
    textCardIndex: index,
    value,
  };
};

/* ****
API CARD ACTIONS
**** */
export const updateApiCardData = (index, data) => {
  return {
    type: actionTypes.UPDATE_API_CARD_DATA,
    payload: data,
    index,
  };
};

export const updateApiCardAction = (index, action) => {
  return {
    type: actionTypes.UPDATE_API_CARD_ACTION,
    payload: action,
    index,
  };
};

export const addApiCardParam = (
  index,
  order,
  api_param_name,
  api_param_value
) => {
  return {
    type: actionTypes.ADD_API_CARD_PARAM_R,
    api_param_name,
    api_param_value,
    index,
    order,
  };
};

export const removeApiCardParam = (index, order) => {
  return {
    type: actionTypes.REMOVE_API_CARD_PARAM_R,
    index,
    order,
  };
};

export const addApiCardTemplate = (
  index,
  order,
  template_location,
  template_data,
  template_usage_type
) => {
  return {
    type: actionTypes.ADD_API_CARD_TEMPLATE_R,
    template_data,
    template_location,
    index,
    order,
    template_usage_type,
  };
};

export const removeApiCardTemplate = (index, order) => {
  return {
    type: actionTypes.REMOVE_API_CARD_TEMPLATE_R,
    index,
    order,
  };
};

export const addApiCardAttribute = (index, order, attribute) => {
  return {
    type: actionTypes.ADD_API_CARD_ATTRIBUTE_R,
    payload: attribute,
    index,
    order,
  };
};

export const removeApiCardAttribute = (index, order) => {
  return {
    type: actionTypes.REMOVE_API_CARD_ATTRIBUTE_R,
    index,
    order,
  };
};

/* ****
SET ATTRIBUTE CARD ACTIONS
**** */
export const updateSetAttributeKey = (index, param1, cust_attr_id) => {
  return {
    type: actionTypes.UPDATE_SET_ATTR_KEY_R,
    index,
    param1,
    cust_attr_id,
  };
};

/* ****
CAROUSEL CARD ACTIONS
**** */
export const removeCarouselImage = (index, slideIndex) => ({
  type: actionTypes.REMOVE_CAROUSEL_SLIDE_IMAGE,
  index,
  slideIndex,
});

export const addCarouselHeading = (index, slideIndex, h_choose_title) => ({
  type: actionTypes.ADD_CAROUSEL_SLIDE_HEADING,
  index,
  slideIndex,
  h_choose_title,
});

export const addCarouselSubheading = (
  index,
  slideIndex,
  h_choose_subtitle
) => ({
  type: actionTypes.ADD_CAROUSEL_SLIDE_SUBHEADING,
  index,
  slideIndex,
  h_choose_subtitle,
});

export const addCarouselURL = (index, slideIndex, click_url) => ({
  type: actionTypes.ADD_CAROUSEL_SLIDE_URL,
  index,
  slideIndex,
  click_url,
});

export const addCarouselClickBlock = (index, slideIndex, click_intent_id) => ({
  type: actionTypes.ADD_CAROUSEL_SLIDE_CLICK_BLOCK,
  index,
  slideIndex,
  click_intent_id,
});

export const addCarouselSlide = (index) => ({
  type: actionTypes.ADD_NEW_CAROUSEL_SLIDE,
  index,
});

export const removeCarouselSlide = (index, slideIndex) => ({
  type: actionTypes.REMOVE_CAROUSEL_SLIDE,
  payload: { index, slideIndex },
});

/* ****
GOTO CARD ACTIONS
**** */
export const addGotoCardAttribute = (index, cust_attr_id, param1 = null) => {
  return {
    type: actionTypes.ADD_GOTO_CARD_ATTRIBUTE_R,
    index,
    cust_attr_id,
    param1,
  };
};

export const addGotoCardChoice = (
  index,
  action_intent_id,
  block_title,
  type,
  choice_title = '',
  isForm = false
) => {
  const choice = generateGotoButtonData(
    action_intent_id,
    block_title,
    type,
    choice_title,
    isForm
  );
  return {
    type: actionTypes.ADD_GOTO_CARD_CHOICE_R,
    index,
    choice,
    choiceType: type,
  };
};

export const addGotoCardIfChoices = (index, list) => {
  const choices = list.map((itm, idx) => {
    const value = itm.value?.value || null;
    const label = itm.value?.label || null;
    return {
      ...generateGotoButtonData(
        value,
        label,
        itm.choice_type,
        itm.name,
        itm.isForm,
        idx + 1 // +1 since index starts at 0
      ),
      action_choice_id: itm.action_choice_id,
    };
  });

  return {
    type: actionTypes.ADD_GOTO_CARD_IF_CHOICE_R,
    index,
    choices,
  };
};

const savingConversationLoader = (status) => ({
  type: actionTypes.SAVING_CONVERSATION_R,
  payload: status,
});

export const saveIntentCards = (
  agent_id,
  cards = [],
  intent_id,
  deleted_cards = []
) => {
  const errors = [];
  let validation = true;
  const receivedCards = [...cards];
  receivedCards.forEach((card) => {
    const error = validateCard(card);
    if (error.valid === false) {
      validation = false;
    }
    card.error = error.text;
    card.validState = error.valid;
  });
  if (validation) {
    const body = { intent_id, cards, deleted_cards };
    return (dispatch) => {
      dispatch(savingConversationLoader(true));
      newApiCall
        .saveIntentCards(agent_id, intent_id, body)
        .then(() => {
          showToast('success', 'Cards saved successfully');
          dispatch(getCardsByIntent(agent_id, intent_id));
          setTimeout(() => {
            return dispatch(savingConversationLoader(false));
          }, 2000);
        })
        .catch((err) => {
          dispatch(savingConversationLoader(false));
          handleErrors(err, 'Could not fetch agents');
        });
    };
  }
  handleErrors('error', generalSaveError);
  return {
    type: actionTypes.HANDLE_ERROR,
    errors,
    receivedCards,
  };
};

export const addIntentCategory = (agent_id, category_name) => {
  return (dispatch) => {
    newApiCall
      .addIntentCategory(agent_id, category_name.trim())
      .then(({ data }) => {
        return dispatch({
          type: actionTypes.UPDATE_CATEGORY_DATA,
          payload: data,
        });
      })
      .catch((err) => {
        handleErrors(err, 'Could not add intent category.');
      });
  };
};

/**
 * @param {object} data
 * @param {import("history").History} [history]
 */
export const addIntent = (data = null, history) => {
  const { intent_inputs } = data;

  if (data.category_id === undefined && data.intent_type === 3) {
    return async (dispatch) => {
      try {
        const { data: category } = await newApiCall.addIntentCategory(
          data.agent_id,
          `${data.agent_id}-Sequence`
        );

        const body = {
          ...data,
          category_id: category.id,
        };

        const { data: intent } = await newApiCall.addIntent(body);

        if (history) {
          history.replace(routes.chatbotDetails.get(data.agent_id, intent.id));
        }

        return dispatch({
          type: actionTypes.UPDATE_ALL_INTENTS_WITH_SEQUENCE,
          payload: intent,
          category,
        });
      } catch (err) {
        handleErrors(err);
      }
    };
  }
  const body = data;
  return async (dispatch) => {
    try {
      const { data: intent_data } = await newApiCall.addIntent(body);

      if (intent_data.status !== undefined) {
        showToast('error', 'Could not add intent');
        return dispatch({
          type: actionTypes.INTENT_NOT_MODIFIED,
        });
      }

      let intent_input_result = [];
      if (
        Array.isArray(intent_inputs) &&
        intent_inputs.length > 0 &&
        intent_data.id
      ) {
        const { data } = await newApiCall.createIntentInputs(
          body.agent_id,
          intent_data.id,
          intent_inputs
        );
        intent_input_result = data;
      }

      if (history) {
        history.replace(
          routes.chatbotDetails.get(data.agent_id, intent_data.id)
        );
      }

      return dispatch({
        type: actionTypes.UPDATE_ALL_INTENTS,
        payload: {
          ...intent_data,
          intent_inputs: intent_input_result,
        },
      });
    } catch (err) {
      handleErrors(err);
    }
  };
};

export const deleteIntent = (
  agent_id,
  intent_id,
  welcome_intent_id,
  currentIntent,
  history
) => {
  return (dispatch) => {
    newApiCall
      .deleteIntent(agent_id, intent_id)
      .then(() => {
        showToast('success', 'Intent deleted successfully!');

        const change_to_welcome_intent =
          welcome_intent_id && intent_id === currentIntent;
        if (change_to_welcome_intent) {
          history.replace(
            routes.chatbotDetails.get(agent_id, welcome_intent_id)
          );
        }

        return dispatch({
          type: actionTypes.DELETE_INTENT,
          payload: { intent_id, change_to_welcome_intent, welcome_intent_id },
        });
      })
      .catch((err) => handleErrors(err, 'Unable to delete intent'));
  };
};

export const deleteFormR =
  ({ formId, agentId, history, currentFormId, welcomeIntentId }) =>
  (dispatch) =>
    newApiCall
      .deleteAgentForm(agentId, formId)
      .then((response) => {
        showToast('success', 'Form deleted successfully!');
        if (Number(formId) === Number(currentFormId)) {
          history.replace(routes.chatbotDetails.get(agentId, welcomeIntentId));
        }
        return dispatch({
          type: actionTypes.DELETE_FORM_R,
          payload: response.data,
          form_id: formId,
        });
      })
      .catch((error) => {
        handleErrors(
          error,
          'Unable to delete. Please ensure form is not already used'
        );
      });

export const updateSequence = (state) => {
  const { sequence, delay_type, delayValue } = state;

  const delay_value = delayValue;
  // set default delay values
  if (delay_value === '' || delay_value === 0) {
    return {
      type: actionTypes.INVALID_SEQUENCE_TIME,
    };
  }
  const timeout_interval = 'PT' + delay_value + delay_type.charAt(0);
  let poll_interval = null;
  switch (delay_type) {
    case 'H':
      if (delay_value > 0 && delay_value <= 12) {
        poll_interval = 'PT10M';
      } else if (delay_value > 12 && delay_value <= 24) {
        poll_interval = 'PT15M';
      }
      break;
    case 'M':
      if (delay_value > 0 && delay_value <= 60) {
        poll_interval = 'PT15S';
      } else {
        poll_interval = 'PT30S';
      }
      break;
    case 'S':
      if (delay_value > 0 && delay_value <= 60) {
        poll_interval = 'PT10S';
      } else {
        poll_interval = 'PT5S';
      }
      break;
    default:
      break;
  }
  const body = {
    ...sequence,
    timeout_interval,
    poll_interval,
  };
  return (dispatch) => {
    newApiCall
      .updateIntent(body)
      .then(({ data }) => {
        return dispatch({
          type: actionTypes.UPDATE_SEQUENCE,
          payload: data,
          data: body,
        });
      })
      .catch((err) => handleErrors(err, 'Could not fetch categories'));
  };
};

export const changeIntentCategory = (payload) => ({
  type: actionTypes.CHANGE_INTENT_CATEGORY,
  payload,
});

export const getApisByAgent = (agent_id) => {
  return (dispatch) => {
    newApiCall
      .getAgentApis(agent_id)
      .then(({ data }) => {
        return dispatch({
          type: actionTypes.GET_APIS_BY_AGENT,
          payload: data,
        });
      })
      .catch((err) => handleErrors(err, 'Could not fetch apis data'));
  };
};

/**
 *
 * @param {{name: string | undefined, index: number, choiceIndex: number}} payload
 * @returns
 */
export const setAttributeIntentChoice = ({ name, index, choiceIndex }) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.SET_ATTRIBUTE_INTENT_CHOICE_R,
      payload: { name, index, choiceIndex },
    });
};
