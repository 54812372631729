import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

import { theme } from '@botco/library';
import { DashboardOverviewMetricsChart } from '~/containers/Dashboard/OverView/components/MetricsChart';
import { METRICS_CARDS } from '~/containers/Dashboard/OverView/constants';

import { PersonalMetricOverviewType } from '../../usePersonalDashboardOverview';
import { usePersonalDashboardOverviewMetricData } from '../../usePersonalDashboardOverviewMetricData';
import { getFormulaChartData } from '../../utils/getFormulaMetricData';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: theme.spacing(1),
    width: '100%',
    height: theme.spacing(50),
  },
}));

type Props = {
  overviewMetric: PersonalMetricOverviewType;
};

export const PersonalDashboardOverviewChart = ({ overviewMetric }: Props) => {
  const classes = useStyles();

  const metricConfig = React.useMemo(() => {
    if (overviewMetric.formula) {
      const metricIds = overviewMetric.formula.match(/#\w+/g) ?? [];

      return METRICS_CARDS.find(
        (m) => m.id === metricIds[0]?.replaceAll('#', '')
      )!;
    }

    return METRICS_CARDS.find(
      (m) => m.id === overviewMetric.id || m.id === overviewMetric.parentId
    )!;
  }, [overviewMetric.formula, overviewMetric.id, overviewMetric.parentId]);

  const { compare, comparedData, data, loading } =
    usePersonalDashboardOverviewMetricData({
      metricId: metricConfig.id,
      metricTag: overviewMetric.parentId ? overviewMetric.title : undefined,
      formula: overviewMetric.formula,
    });

  if (loading) {
    return (
      <Skeleton animation="pulse" variant="rect" className={classes.skeleton} />
    );
  }

  const chartData = overviewMetric.formula
    ? getFormulaChartData(overviewMetric.formula, data).details
    : metricConfig.getCurrentData(data).details;

  const previousChartData = overviewMetric.formula
    ? getFormulaChartData(overviewMetric.formula, comparedData).details
    : metricConfig.getCurrentData(comparedData).details;

  return (
    <DashboardOverviewMetricsChart
      showTitle={false}
      currentMetric={metricConfig.id}
      previousData={compare ? previousChartData : undefined}
      data={chartData}
      color={theme.palette.secondary.main}
    />
  );
};
