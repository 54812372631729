import { differenceInDays, subDays } from 'date-fns';

import { QueryConditionsDto } from '~/__generated__/query-service';
import { useDashboardQueryServiceContext } from '~/containers/Dashboard/Provider/DasboardQueryServiceProvider';
import { useDashboardContext } from '~/containers/Dashboard/Provider/DashboardProvider';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import {
  QueryServiceMetricsConfig,
  QueryServiceMetricsConfigKey,
} from '~/types/query-service-helpers';

type Params = {
  metricId: QueryServiceMetricsConfigKey;
  metricTag?: string;
  formula?: string;
};

const cleanGoalMetric = (metric: string) =>
  metric.replace(' (Count)', '').replace(' ($)', '');

export const usePersonalDashboardOverviewMetricData = ({
  metricId,
  metricTag,
  formula,
}: Params) => {
  const { filters } = useDashboardContext();
  const { conditions } = useDashboardQueryServiceContext();

  const metricIds = formula
    ?.match(/#\w+/g)
    ?.map((m) => m.replaceAll('#', '')) ?? [metricId];

  const metricsProjection = metricIds.map((m) =>
    m === metricId
      ? {
          ...QueryServiceMetricsConfig[m as QueryServiceMetricsConfigKey],
          ...(metricTag && {
            options: { 'metric/tag': cleanGoalMetric(metricTag) },
          }),
        }
      : QueryServiceMetricsConfig[m as QueryServiceMetricsConfigKey]
  );

  const { isLoading, data } = useQueryServiceApi(
    ['dashboard', conditions, metricsProjection],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: metricsProjection,
        },
      }),
    {
      enabled: Boolean(metricId || formula) && Boolean(conditions),
    }
  );

  const { isLoading: isComparedLoading, data: comparedData } =
    useQueryServiceApi(
      ['dashboard', 'compare', conditions, metricId],
      (Api) => {
        let lenDays = differenceInDays(
          new Date(filters.endTime),
          new Date(filters.startTime)
        );

        if (lenDays <= 0) {
          lenDays = 1;
        }

        const compareConditions = {
          ...conditions,
          datetime_range: {
            ...conditions?.datetime_range,
            from: subDays(new Date(filters.startTime), lenDays).toISOString(),
            until: subDays(new Date(filters.endTime), lenDays).toISOString(),
          },
        } satisfies QueryConditionsDto;

        return Api.query.query({
          requestBody: {
            conditions: compareConditions!,
            projection: metricsProjection,
          },
        });
      },
      {
        enabled:
          Boolean(metricId || formula) &&
          Boolean(conditions) &&
          filters.compare,
      }
    );

  return {
    loading: isLoading || isComparedLoading,
    data,
    comparedData,
    compare: filters.compare,
  };
};
