import { CircularProgress, makeStyles } from '@material-ui/core';
import {
  BorderColorRounded as PencilIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { clsx } from 'clsx';
import * as React from 'react';

import { Typography } from '@botco/library';
import { METRICS_CARDS } from '~/containers/Dashboard/OverView/constants';

import { PersonalMetricOverviewType } from '../../usePersonalDashboardOverview';
import { usePersonalDashboardOverviewMetricData } from '../../usePersonalDashboardOverviewMetricData';
import { getFormulaOverviewData } from '../../utils/getFormulaMetricData';

const useStyles = makeStyles((theme) => ({
  title: {
    userSelect: 'none',
    textAlign: 'left',
  },
  titleWrapper: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
    height: 28,
  },
  headerButtonWrapper: {
    display: 'flex',
    visibility: 'hidden',
    gap: theme.spacing(0.5),
  },
  headerButton: {
    borderRadius: 5,
    padding: '5px 6px',
    border: 'none',
    display: 'flex',
    backgroundColor: theme.palette.grey[200],
    alignItems: 'center',

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },

    '&:active': {
      backgroundColor: theme.palette.grey[400],
    },

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  valueWrapper: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  overviewMetric: PersonalMetricOverviewType;
  onEdit: (e: React.MouseEvent<HTMLElement>) => void;
  onRemove: (e: React.MouseEvent<HTMLElement>, metricId: string) => void;
};

export const PersonalDashboardOverviewTabHeader = ({
  overviewMetric,
  onEdit,
  onRemove,
}: Props) => {
  const classes = useStyles();

  const metricConfig = React.useMemo(() => {
    if (overviewMetric.formula) {
      const metricIds = overviewMetric.formula.match(/#\w+/g) ?? [];

      return METRICS_CARDS.find(
        (m) => m.id === metricIds[0]?.replaceAll('#', '')
      )!;
    }

    return METRICS_CARDS.find(
      (m) => m.id === overviewMetric.id || m.id === overviewMetric.parentId
    )!;
  }, [overviewMetric.id, overviewMetric.formula, overviewMetric.parentId]);

  const { compare, comparedData, data, loading } =
    usePersonalDashboardOverviewMetricData({
      metricId: metricConfig.id,
      metricTag: overviewMetric.parentId ? overviewMetric.title : undefined,
      formula: overviewMetric.formula,
    });

  const value = overviewMetric.formula
    ? getFormulaOverviewData(overviewMetric.formula, data)
    : metricConfig.getValue(data);

  const previousValue = overviewMetric.formula
    ? getFormulaOverviewData(overviewMetric.formula, comparedData)
    : metricConfig.getValue(comparedData);

  const formatFn = metricConfig.formatFn ?? ((value: unknown) => `${value}`);

  const diff = (() => {
    if (typeof previousValue !== 'number') {
      return null;
    }

    if (typeof value !== 'number') {
      return null;
    }

    if (value === 0 && previousValue === 0) {
      return 0;
    }

    if (previousValue === 0) {
      return 100;
    }

    return (((value - previousValue) / previousValue) * 100)
      .toFixed(2)
      .replace('.00', '');
  })();

  return (
    <>
      <div className={classes.titleWrapper}>
        <Typography fontWeight="700" className={classes.title}>
          {overviewMetric.title}
        </Typography>
        <div id="overview-card-button" className={classes.headerButtonWrapper}>
          <div className={classes.headerButton} onClick={onEdit}>
            <PencilIcon />
          </div>
          <div
            role="button"
            className={classes.headerButton}
            onClick={(e) => onRemove(e, overviewMetric.id)}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className={classes.fullWidth}>
        <div className={classes.valueWrapper}>
          <Typography
            variant="h2"
            data-testid={`dashboard-metric-card-value-${overviewMetric.id}`}
          >
            {loading ? (
              <CircularProgress size={32} />
            ) : typeof value === 'number' ? (
              formatFn(value)
            ) : (
              'N/A'
            )}
          </Typography>
          {compare && typeof diff !== 'undefined' && (
            <Typography
              variant="h3"
              data-testid={`dashboard-metric-card-compare-value-${overviewMetric.id}`}
              color="textPrimary"
              className={clsx({
                [classes.success]: Number(diff) > 0,
                [classes.error]: Number(diff) < 0,
              })}
            >
              {Number(diff) > 0 ? '+' : ''}
              {diff}%
            </Typography>
          )}
        </div>
        {compare && typeof previousValue === 'number' && (
          <Typography variant="subtitle2" align="left" color="textSecondary">
            {formatFn(previousValue)}
          </Typography>
        )}
      </div>
    </>
  );
};
