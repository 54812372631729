/* eslint-disable no-redeclare */
import { components } from '~/__generated__/botco-api-schema';

export const IntentType = {
  NORMAL: 0,
  DEFAULT: 1,
  LOCATION: 2,
  INACTIVITY: 3,
  ACCOUNT_LINK: 4,
  ACCOUNT_UNLINK: 5,
  COMPILED: 6,
  SIMPLE: 7,
  GOT_IMAGE: 8,
  GOT_VIDEO: 9,
  GOT_AUDIO: 10,
} as const;

export type IntentType = EnumValueType<typeof IntentType>;

export type IntentInputs = components['schemas']['IntentInput'];

export type IntentContextOut =
  components['schemas']['IntentCategoryContextOut'];

export type IntentContextIn = components['schemas']['IntentCategoryContextIn'];

export type Intent = components['schemas']['Intent'];

export type AgentApis = Required<components['schemas']['AgentApiEndpointDto']>;

export type AgentCategory = {
  id: number;
  intents: string[] | Intent[];
  category_name: string;
  category_order: number;
  agent_id: number;
  is_system: number;
  intent_cat_context_ins: string[];
  intent_cat_context_outs: string[];
};

export type AgentAuditTrail = Required<
  Omit<components['schemas']['DtoAudit'], 'changedValues' | 'type'>
> & {
  changedValues: string | null;
  type: 'AUDIT_DEPLOY' | 'AUDIT_INTENT';
};

export type AgentForm = components['schemas']['AgentForm'];

export type Attribute = components['schemas']['CustomerAttribute'];

export type Entity = components['schemas']['AgentEntityRequest'];

export type AgentCategoryType = 'group' | 'block';

export type IntentAction = components['schemas']['IntentAction'];

export type IntentCarousel = components['schemas']['IntentHChoose'];

export type IntentHChooseButton = components['schemas']['IntentHChooseButton'];

export type IntentChoice = components['schemas']['IntentActionChoice'];

export const FormEntityTypes = {
  SYSTEM: 2,
  CUSTOM: 1,
} as const;

type InputHistory = {
  update_user: string;
  history: never[];
  create_user: string;
  create_date: string;
  intent_id?: number;
  input_value: string;
  input_id?: number;
  update_date: string;
};

export type InputHistoryContent = {
  change_id: number;
  change_user_id: number;
  change_date: Date;
  before_values: InputHistory;
  after_values: InputHistory;
};
