import { Collapse, Menu, MenuItem, MenuProps } from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { clsx } from 'clsx';
import * as React from 'react';

import { InfoIcon, SearchInput, Tooltip, Typography } from '@botco/library';
import { usePersonalDashboard } from '~/containers/PersonalDashboard/Provider/PersonalDashboardProvider';
import { PersonalDashboardMetric } from '~/utils/http/dashboard/types';

import { useHiddenMetricsMenuStyles } from './useHiddenMetricsMenuStyles';
import { PersonalMetricOverviewType } from '../../usePersonalDashboardOverview';

interface Props extends Pick<MenuProps, 'anchorEl' | 'open'> {
  onClose: () => void;
  onClick: (overviewMetricId: PersonalMetricOverviewType['id']) => void;
}

export const HiddenMetricsMenu = ({
  anchorEl,
  open,
  onClose,
  onClick,
}: Props) => {
  const classes = useHiddenMetricsMenuStyles();
  const { personalMetrics } = usePersonalDashboard();

  const hiddenMetrics = personalMetrics.filter(
    (metric) =>
      metric.status === 'hidden' &&
      (Array.isArray(metric.submetrics) ? metric.submetrics.length > 0 : true)
  );

  const [search, setSearch] = React.useState('');
  const [openSubmenus, setOpenSubmenus] = React.useState<
    Record<string, boolean>
  >(
    hiddenMetrics
      .filter((m) => Boolean(m.submetrics?.length))
      .reduce((acc, m) => ({ ...acc, [m.id]: true }), {})
  );

  const filteredMetrics = hiddenMetrics.filter(
    (metric) =>
      metric.title.toLowerCase().includes(search.toLowerCase()) ||
      (metric.submetrics &&
        metric.submetrics.some((submetric) =>
          submetric.title.toLowerCase().includes(search.toLowerCase())
        ))
  );

  const handleMetricClick = (metric: PersonalDashboardMetric) => {
    if (metric.submetrics?.length) {
      setOpenSubmenus((curr) => ({
        ...curr,
        [metric.id]: !curr[metric.id],
      }));
      return;
    }

    onClick(metric.id);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      elevation={2}
      onClose={onClose}
      disableEnforceFocus
      disableAutoFocus
      disableAutoFocusItem
      keepMounted
      getContentAnchorEl={null}
      data-testid="hidden-metric-list"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      MenuListProps={{
        style: {
          minWidth: 250,
          maxWidth: 250,
          width: 'min-content',
        },
      }}
      PaperProps={{ style: { maxHeight: 400 } }}
    >
      <div className={classes.searchWrapper}>
        <SearchInput
          autoFocus
          name="search"
          value={search}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(_, value) => setSearch(value)}
          externalClasses={{ searchField: classes.searchInput }}
        />
      </div>
      <li className={classes.menuHeader}>
        <Typography fontWeight="600" color="inherit">
          Select a metric
        </Typography>
      </li>
      {filteredMetrics.map((metric) => (
        <div key={metric.id}>
          <MenuItem
            className={classes.menuItem}
            disableGutters
            disableRipple
            onClick={() => handleMetricClick(metric)}
          >
            <div className={classes.fullWidth}>
              <Typography className={classes.textWrap}>
                {metric.title}
              </Typography>
              {metric.tooltip && (
                <Tooltip title={metric.tooltip}>
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              )}
            </div>
            {Boolean(metric.submetrics?.length) &&
              (openSubmenus[metric.id] ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              ))}
          </MenuItem>
          {Boolean(metric.submetrics?.length) && (
            <Collapse
              in={openSubmenus[metric.id]}
              classes={{ wrapper: classes.fullWidth }}
            >
              <div className={classes.submenu}>
                {metric.submetrics
                  ?.filter((s) => s.status === 'hidden')
                  .map((subMetric) => (
                    <MenuItem
                      key={subMetric.id}
                      className={clsx(classes.menuItem, classes.submenuItem)}
                      disableGutters
                      disableRipple
                      onClick={() => onClick(subMetric.id)}
                    >
                      <Typography className={classes.textWrap}>
                        {subMetric.title}
                      </Typography>
                    </MenuItem>
                  ))}
              </div>
            </Collapse>
          )}
        </div>
      ))}
      {filteredMetrics.length === 0 && (
        <MenuItem
          disabled
          className={classes.menuItem}
          disableGutters
          disableRipple
        >
          No metrics found
        </MenuItem>
      )}
    </Menu>
  );
};
