import * as React from 'react';

import { PersonalDashboardMetric } from '~/utils/http/dashboard/types';

import { usePersonalDashboard } from '../../Provider/PersonalDashboardProvider';

export interface PersonalMetricOverviewType
  extends Omit<PersonalDashboardMetric, 'id' | 'submetrics' | 'tooltip'> {
  id: string;
  parentId?: string;
  tooltip?: string;
}

export const usePersonalDashboardOverview = () => {
  const { personalMetrics } = usePersonalDashboard();

  const allMetrics: PersonalMetricOverviewType[] = personalMetrics.reduce(
    (acc, curr) => {
      if (curr.submetrics?.length) {
        return [
          ...acc,
          ...curr.submetrics.map((sm) => ({
            parentId:
              curr.id === 'Goals' && sm.id.includes('count')
                ? ('GoalsCount' as const)
                : curr.id,
            ...sm,
          })),
        ];
      }

      return [...acc, curr];
    },
    [] as PersonalMetricOverviewType[]
  );

  const shouldShowAddMetric = allMetrics.some((m) => m.status === 'hidden');

  const shownMetrics = React.useMemo(
    () => allMetrics.filter((m) => m.status === 'show'),
    [allMetrics]
  );

  const [selectedMetricId, setSelectedMetricId] = React.useState<
    PersonalMetricOverviewType['id']
  >(shownMetrics[0]?.id ?? 'Views');

  return {
    shownMetrics,
    selectedMetricId,
    setSelectedMetricId,
    shouldShowAddMetric,
  };
};
